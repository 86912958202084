<template>
  <div
    class="mats-listing__wrapper color-listing__wrapper "
    style="margin-top: 0px;"
  >
    <button
      v-b-modal.modal-add-color
      style="visibility: hidden;"
      class="button"
    >
      <b-icon-plus />
      {{ $t('AddColor') }}</button>
    <div
      class="table__search-input"
      style="margin-top: 6px !important;"
    >
      <b-icon-search />
      <input
        v-model="searchTerm"
        type="text"
      >
    </div>
    <div style="width: 100%; ">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('TeamLeaderName') }}
            </th>
            <th>
              {{ $t('Employees') }}
            </th>
            <th v-if="client != 'NotaBrillant'">
              {{ $t("IsOnVacation") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="getTeamOverview.teamLeaderUserId && materialId != ''"
            class="hover-row"
          >
            <td class="leader-con">
              {{ getTeamOverview.teamLeaderName }}
            </td>
            <td
              v-if="getTeamOverview.users.length != 0"
              class="sewer-con"
            >
              <div
                v-for="(item, index) in getTeamOverview.users"
                :key="item.handSewerUserId"
                style="height: 27px; display: flex;align-items: center; margin-bottom: 8px;"
              >

                <div
                  style="display: flex; align-items: flex-start;"
                >
                  <p
                  >{{ index + 1 }}. {{ $t(item.name) }} - <b>{{ $t(item.role) }}</b></p>
                </div>
              </div>
            </td>
            <td
              v-if="getTeamOverview.users.length != 0 && client != 'NotaBrillant'"
              class="pattern-con"
            >
              <div
                v-for="item in getTeamOverview.users"
                :key="item.handSewerUserId"
                class=""
              >
                <div style="display: flex; align-items: flex-start; font-size: 12px !important;">
                  <div
                    v-if="item.isOnVacation == true"
                    id="checkbox-sizes"
                    class="checkboxes"
                    :name="`checkbox`"
                    :value="true"
                    :disabled="true"
                    :unchecked-value="false"
                  >
                    <div style="display: flex;align-items: center;">
                      <button id="buttonYes">
                        <b-icon-check style="width: 20px; height: 20px;color:green;" />
                        {{ $t('Yes') }}
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="item.isOnVacation == false"
                    id="checkbox-sizes"
                    class="checkboxess"
                    :name="`checkbox`"
                    :value="false"
                    indeterminate="indeterminate"
                    :disabled="true"
                    :unchecked-value="false"
                  >
                    <div style="display: flex;align-items: center;">
                      <button id="buttonNo">
                        <b-icon-x style="width: 20px; height: 20px;color:#B42318;" />
                        {{ $t('NoCheckBox') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <ShowWorkEmployee
      :year="year"
      :month="month"
      :name-of-employee="nameOfEmployee"
      :user-id-of-employee="userIdOfEmployee"
      @onCancelToggle="onCancelToggle"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import ShowWorkEmployee from '@/components/teams/modals/ShowWorkEmployee.vue'
import { client } from '../../domainConfig'

export default {
  components: {
    ShowWorkEmployee,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['materialId'],
  data() {
    return {
      isSidebarOpen: false,
      nameOfEmployee: null,
      userIdOfEmployee: null,
      client: client.clientName,
      isChecked: true,
      initialId: '',
      materialPropId: '',
      status: true,
      classNameCh: '',
      year: moment().year(),
      month: moment().month() + 1,
      searchTerm: '',
      rows: [],
      materialObject: [],
      editMat: {},
      filters: {
        page: 1,
        pageSize: 25,
        query: '',

        ...this.$route.query,
      },
    }
  },
  computed: {
    ...mapGetters(['getTeamOverview']),
    // rowsEr() {
    //   return this.rows.filter(post => post.colorName.toLowerCase().includes(this.searchTerm.toLowerCase()))
    // },
    from() {
      return (this.filters.page - 1) * this.filters.pageSize + 1
    },
    to() {
      return (this.filters.page * this.filters.pageSize) > this.totalItems ? this.totalItems : (this.filters.page * this.filters.pageSize)
    },
  },
  watch: {
    materialObject() {
      this.addMaterialItem()
    },
    initialId() {
      this.immediateId(this.initialId)
    },
  },
  methods: {
    ...mapActions(['loadTrackAndTraceByEmployee', 'loadFeedback']),
    showWorkOfEmployee(value) {
      this.isSidebarOpen = true
      this.nameOfEmployee = value.name
      this.userIdOfEmployee = value.userId
      this.loadTrackAndTraceByEmployee({
        processingUserId: value.userId,
        year: this.year,
        month: this.month,
        pageNumber: 1,
        pageSize: 15,
      })
      this.loadFeedback({
        userId: value.userId,
        year: this.year,
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    editMaterial(item) {
      this.editMat = item
      this.$bvModal.show('modal-edit-dress')
    },
    materialsData(obj) {
      this.materialObject = obj
    },

    immediateId(e) {
      this.$emit('click', e)
    },
    matProps(mat) {
      this.materialPropId = mat.materialId
      this.$emit('click', mat)
      this.classNameCh = mat.colorName
    },
  },
}
</script>

<style scoped lang="scss">

.team_table td {
  padding: 14px 9px !important;
  border-radius: 0px;
}

// .checkboxes{
//   padding-left:8px;
//   padding-right:8px;padding-top:1px;
//   padding-bottom:1px;margin-bottom: 16px;
//   border-radius: 35%;background-color: #ABEFC6;
//   border-color: #ECFDF3;
//   ;margin-bottom: 0px;
// }
// .checkboxess{
//   padding-left:8px;
//   padding-right:8px;
//   padding-top:1px;
//   padding-bottom:1px;
//   margin-bottom: 16px;
//    border-radius: 35%;
//    background-color: #FECDCA;
//    border-color: #FEF3F2;
//    ;margin-bottom: 0px;
// }

#buttonYes {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
  background: #ECFDF3;
  color: #067647;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #ABEFC6;
  border-radius: 1000px;
  cursor: default;
}

#buttonNo {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
  background: #FEF3F2;
  color: #B42318;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #FECDCA;
  border-radius: 1000px;
  cursor: default;
}
  .color-listing__wrapper {
    margin-left: 20px;
    .mats-listing {
      margin-top: 0;
      padding: 5px;
      // width: 80%;
    }
    .table__search-input {
      visibility: hidden;
    }
    input {
      width: 230px;
    }
  }
  .leader-con {
    // border: 1px solid lightgray;
    padding: 8px;
    border-radius: 8px;
    margin-right: 15px;
    width: 200px;


    h5 {
      border-bottom: 1px solid #232323;
      background-color: #F3F3F3;
      text-align: left;
      font-weight: 1000;
      font-size: 12px;
      height: 30px;
    padding-top: 7px;
    }

    p{
      color: black;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .sewer-con {
    // border: 1px solid lightgray;
    padding: 8px;
    border-radius: 8px;
    margin-right: 15px;
    width: 300px;


    h5 {
      border-bottom: 1px solid #232323;
      background-color: #F3F3F3;
      text-align: left;
      font-weight: 1000;
      font-size: 12px;
      height: 30px;
    padding-top: 7px;
    }
    p{
      color: black;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .pattern-con {
    // border: 1px solid lightgray;
    padding: 8px;
    border-radius: 8px;
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 6px;


    h5 {
      border-bottom: 1px solid #232323;
      background-color: #F3F3F3;
      text-align: left;
      font-weight: 1000;
      font-size: 12px;
      height: 30px;
    padding-top: 7px;
    }
    p{
      color: black;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .mat {
    display: flex;
    justify-content: space-between;
    align-content: center;
    &.active {
      background-color: #e8e8e8;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    span {
      margin-left: 10px;
      svg {
        font-size: 15px;
      }
    }
  }
  .test{
    margin-top: -5px;
  }
@media screen and (max-width: 673px) {
.color-listing__wrapper {
  margin-left: 0 !important;
}
}
</style>
